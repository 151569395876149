import {gsap} from "gsap";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {SplitText} from "gsap/SplitText";

gsap.registerPlugin(DrawSVGPlugin, MorphSVGPlugin, ScrollTrigger, ScrollToPlugin, SplitText);

jQuery(document).ready(function () {
  //
  // let menu_burger = document.getElementById('js-menu-burger');
  // let menu_burger__bg = document.getElementById('js-menu-burger-bg');
  // let tl = gsap.timeline({'paused': true});
  // tl.to(menu_burger__bg, {
  //     'duration': 0.5,
  //     'width': '300vw',
  //     'height': '300vw',
  //     'backgroundColor': '#1993A4',
  //     'borderRadius': '0px',
  //     'ease': "power2.easeInOut",
  //     'top': 0,
  //     'left': 0,
  //     'transform': 'unset',
  //   }
  // );
  //
  // menu_burger.addEventListener("click", function () {
  //   tl.reversed() ? tl.play() : tl.reverse();
  // });

  let menu_burger = document.getElementById('js-menu-burger');

  if (menu_burger) {
    let menu_burger__main = jQuery('#js-menu-burger-main');
    let menu_burger__close = jQuery('#js-menu-burger-close');
    menu_burger.addEventListener("click", function () {
      //menu_burger__main.css({'display': 'flex'});
      menu_burger__main.addClass('js-active');
      menu_burger__close.addClass('js-active');
      jQuery('html').addClass('overflow-hidden');
    });
    menu_burger__close.on("click", function () {
      //menu_burger__main.css({'display': 'none'});
      menu_burger__main.removeClass('js-active');
      menu_burger__close.removeClass('js-active');
      jQuery('html').removeClass('overflow-hidden');
    });

    menu_burger__main.find('.page-menu-burger__main-row1-col1 > .menu').mouseenter(function () {
      jQuery(this).find('.menu-item:not(.menu-item-has-children) .menu-item-href').css('opacity', '0.5');
    });

    menu_burger__main.find('.page-menu-burger__main-row1-col1 > .menu').mouseleave(function () {
      jQuery(this).find('.menu-item:not(.menu-item-has-children) .menu-item-href').css('opacity', '1');
    });

  }

  jQuery('.js-loop-text').each(function () {
    let container_width = jQuery(window).width();
    let element = jQuery(this).find('.js-loop-text-string');
    let element_width = element.width() + 20;
    // jQuery(this).css('left', -element_width);
    //
    // let number_clone = Math.ceil(container_width / element_width) + 2;
    // for (let i = 1; i <= number_clone; i++) {
    //   element.clone().appendTo(jQuery(this));
    // }
    //
    // var totalWidth = element_width * number_clone,  //  * n of boxes
    //   dirFromLeft = "-=" + totalWidth;
    //
    // var mod = gsap.utils.wrap(0, totalWidth);

    // var master = gsap.timeline({paused: true})
    //   .add(marquee(jQuery(this).find('.js-loop-text-string'), 60, dirFromLeft, mod, element_width))
    // master.play();
    //
    // jQuery(this).parents('.sc_loop_text').mouseleave(function (e) {
    //   gsap.to(this, 0.3, {scale: 1});
    //   gsap.to('.sc_loop_text__button-circle, .sc_loop_text__button-burger', 0.3, {scale: 1, x: 0, y: 0});
    // });
    //
    // jQuery(this).parents('.sc_loop_text').mouseenter(function (e) {
    //   gsap.to(this, 0.3, {transformOrigin: '0 0', scale: 1});
    //   gsap.to('.sc_loop_text__button-circle', 0.3, {scale: 1.3});
    // });

    jQuery(this).parents('.sc_loop_text').mousemove(function (e) {
      callParallax(e);
    });
  });
});

function marquee(which, time, direction, mod, boxWidth) {
  gsap.set(which, {
    x: function (i) {
      return i * boxWidth;
    }
  });
  var action = gsap.timeline()
    .to(which, {
      x: direction,
      modifiers: {
        x: x => mod(parseFloat(x)) + "px",
      },
      duration: time,
      ease: 'none',
      repeat: -1,
    });
  return action
}

function callParallax(e) {
  // parallaxIt(e, '.sc_loop_text__button-circle', 10);
  // parallaxIt(e, '.sc_loop_text__button-burger', 10);
  parallaxIt(e, '.sc_loop_text__button-href', 10);
}

function parallaxIt(e, target, movement) {
  var $this = jQuery('.sc_loop_text__button-wrapper');
  var boundingRect = $this[0].getBoundingClientRect();
  var relX = e.pageX - boundingRect.left;
  var relY = e.pageY - boundingRect.top;
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  gsap.to(target, 0.3, {
    x: (relX - boundingRect.width / 2) / boundingRect.width * movement,
    y: (relY - boundingRect.height / 2 - scrollTop) / boundingRect.width * movement,
    ease: "power2.easeOut"
  });
}

jQuery(document).ready(function () {
  initialise();

  // resizeBanner();

  readMore();

  sc_page_list_create();

  horizontal_scroll();

  jQuery('.scroll-down').click(function () {
    gsap.to(window, {'duration': 1, scrollTo: '#page-content'});
  });
});

jQuery(document).ajaxComplete(function () {
  initialise();
});

jQuery(window).resize(function () {
  // resizeBanner();

  sc_page_list_refresh();
});

function initialise() {
  jQuery('.button').each(function () {
    if(jQuery(this).hasClass('is-anim')) {
      return;
    }

    jQuery(this).addClass('is-anim');

    let button_content = jQuery(this).html();
    jQuery(this).html('').append('<span class="button__before"></span><span class="button__content">' + button_content + '</span>');

    const button_timeline = gsap.timeline({repeat: 0, repeatDelay: 1});
    button_timeline.to(jQuery(this).find('.button__before'), {
        width: '100%',
        duration: 0.75,
      }
    );
    button_timeline.reverse();

    jQuery(this).on('mouseenter', () => {
      button_timeline.play();
    });
    jQuery(this).on('mouseleave', () => {
      button_timeline.reverse();
    });
    jQuery(this).on('focusin', () => {
      button_timeline.play();
    });
    jQuery(this).on('focusout', () => {
      button_timeline.reverse();
    });
  });

  jQuery('.site-landing h2, .site-landing .h2').each(function () {
    if (jQuery(this).hasClass('is-added')) {
      return;
    }

    jQuery(this).addClass('is-added');

    let title_content = jQuery(this).html();
    jQuery(this).html('').append('<span class="title__block"><span class="title__before"></span><span class="title__content">' + title_content + '</span></span>');
  });
}

/*
 * Gestion de la bannière
 */
function resizeBanner() {
  if (jQuery(window).width() < 768) {
    jQuery('#js-button-banner-move').appendTo('.banner__thumbnail');
  } else {
    jQuery('#js-button-banner-move').appendTo('.banner__content');
  }
}

/*
 * Implémentation du bouton "readmore"
 */
function readMore(action = 'hide') {
  let elements =  jQuery('.js-read-more');

  if (action === 'hide') {
    elements.each(function () {
      let parent = jQuery(this).parent();
      let read_content = parent.nextAll().detach();

      let content_block = jQuery('<div class="js-read-more-content"></div>').appendTo(parent.parent());
      read_content.appendTo(content_block);
    });
  }

  elements.click(function (e) {
    e.preventDefault();

    let tl = gsap.timeline();
    tl.to(jQuery(this).parent(), {ease: 'power0', opacity: 0, overflow: 'hidden', display: 'none'});
    tl.to(jQuery(this).parent().next(), {
      ease: 'power0', opacity: 1, height: 'auto', onComplete: function () {
        ScrollTrigger.refresh();
      }
    });
  });
}

/*
 * Shortcode sc_page_list : Création des clones.
 */
function sc_page_list_create() {
  jQuery('.sc_page_list__container').each(function () {
    let parent = jQuery(this).parent();
    let clone = jQuery(this).clone();
    clone.find('.fill-effect-line').addClass('fill-effect-line--white');
    clone.appendTo(parent).addClass('sc_page_list__container--clone');
    jQuery(this).find('.sc_page_list__posts__thumbnails').remove();
    parent.find('.sc_page_list__list').css('width', parent.width());
  });
}

/*
 * Shortcode sc_page_list : Mettre à jour la largeur.
 */
function sc_page_list_refresh() {
  jQuery('.sc_page_list__container').each(function () {
    let parent = jQuery(this).parent();
    parent.find('.sc_page_list__list').css('width', parent.width());
  });
}

/*
 * Implémentation d'un scroll horizontal
 */
function horizontal_scroll() {
  if (jQuery('.js-horizontal-scroll').length) {
    let container = document.querySelector('.horizontal-container')
    ScrollTrigger.create({
      trigger: ".js-horizontal-scroll",
      start: "bottom bottom",
      end: () => "+=" + (container.scrollWidth - window.innerWidth),
      pin: '#page',
      anticipatePin: 1,
      scrub: 0.5,
      invalidateOnRefresh: true,
      // markers: true,
    })

    let thumbNails = gsap.utils.toArray(".horizontal-element");
    thumbNails.forEach((thumb, i) => {
      gsap.to(thumb, {
        x: () => {
          return -(container.scrollWidth - window.innerWidth)
        },
        ease: "none",
        scrollTrigger: {
          trigger: ".horizontal-wrapper",
          start: 'bottom bottom',
          scrub: 0.5,
          invalidateOnRefresh: true,
          end: () => "+=" + (container.scrollWidth - window.innerWidth)
        }
      });
    });
  }
}

import './gsap-fill-effect';
import './gsap-flip-button';
import './gsap-image-reveal';
import './gsap-anim-button';
//import './gsap-text-reveal';
