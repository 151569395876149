import {gsap, Power2} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

jQuery(window).ready(function () {
  addImageReveal();

  // if (jQuery('.js-image-reveal').length) {
  //   imageReveal();
  // }
});

function addImageReveal() {
  let images = jQuery('.site-content').find('img:not(.js-image-reveal-no)');

  images.each(function () {
    jQuery(this).parent().addClass('js-image-reveal');
  });
}

function imageReveal() {
  let images = jQuery('.js-image-reveal');

  images.each(function () {
    let image = jQuery(this).find('img');
    if(!image.length) {
      return;
    }

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: jQuery(this),
        toggleActions: "restart none none reset"
      }
    });

    tl.set(jQuery(this), {autoAlpha: 1});
    tl.from(
      jQuery(this),
      {
        duration: 1,
        xPercent: -100,
        ease: Power2.out
      }
    );
    tl.from(
      image,
      {
        duration: 1,
        xPercent: 100,
        scale: 1.2,
        delay: -1,
        ease: Power2.out
      }
    );
  });
}
