import {gsap, Power2} from "gsap";
import {SplitText} from "gsap/SplitText";

gsap.registerPlugin(SplitText);

jQuery(window).load(function () {
  let document_width = jQuery(document).width();
  if (document_width >= 1024) {
    if (jQuery('.fill-effect-action').length) {
      fillEffect('.fill-effect-line');
    }
  }
});

function fillEffect(target_class) {
  gsap.utils.toArray(target_class).forEach(function (line, i) {
    let text = new SplitText(line, {type: "words, chars", wordDelimiter:"-"}),
      letters = text.chars;

    let color = '#03344C';
    if (line.classList.contains('fill-effect-line--white')) {
      color = '#FFF';
    }

    let action = gsap.timeline({paused: true, overwrite: true})
      .to(letters, {
        color: color,
        duration: 0.01,
        ease: 'none',
        stagger: 0.02,
        textFillColor: color,
      });

    jQuery(line).mouseenter(function () {
      action.timeScale(1).play();

      let container = jQuery(this).parents('.fill-effect-action');
      let container_clone = container.next();
      if (container_clone.length && !container_clone.hasClass('is-origin')) {
        container.addClass('is-origin');
      }

      let parent = jQuery(this).parent();
      parent.addClass('active');
      /*
      let images = parent.find('img');
      if(images.length) {
        images.each(function () {
          fillEffectImageReveal(jQuery(this).parent());
        });
      }
      */

      if (container_clone.length && !container_clone.hasClass('is-origin')) {
        let element_clone = container_clone.find('.fill-effect-list li').eq(parent.index());
        element_clone.find('.fill-effect-line').trigger('mouseenter');
      }

      let origin_clone = container.prev();
      if (origin_clone.length && !origin_clone.hasClass('is-origin')) {
        container.addClass('is-origin');
      }
      if (origin_clone.length && container.hasClass('is-origin') && origin_clone.hasClass('fill-effect-action')) {
        let element_origin = origin_clone.find('.fill-effect-list li').eq(parent.index());
        element_origin.find('.fill-effect-line').trigger('mouseenter');
      }
    });

    jQuery(line).mouseleave(function () {
      action.timeScale(2).reverse();

      let container = jQuery(this).parents('.fill-effect-action');
      let container_clone = container.next();

      let parent = jQuery(this).parent();
      parent.removeClass('active');

      if (container_clone.length && !container_clone.hasClass('is-origin')) {
        let element_clone = container_clone.find('.fill-effect-list li').eq(parent.index());
        element_clone.find('.fill-effect-line').trigger('mouseleave');
      }

      let origin_clone = container.prev();
      if (origin_clone.length && !origin_clone.hasClass('is-origin') && origin_clone.hasClass('fill-effect-action')) {
        let element_origin = origin_clone.find('.fill-effect-list li').eq(parent.index());
        element_origin.find('.fill-effect-line').trigger('mouseleave');
      }

      container.removeClass('is-origin');
    });
  })
}

function fillEffectImageReveal(element) {
  let image = element.find('img');
  if (!image.length) {
    return;
  }

  let tl = gsap.timeline();
  tl.set(element, {autoAlpha: 1});
  tl.from(
    element,
    {
      duration: 1,
      xPercent: -100,
      ease: Power2.out
    }
  );
  tl.from(
    image,
    {
      duration: 1,
      xPercent: 100,
      scale: 1.2,
      delay: -1,
      ease: Power2.out
    }
  );
}
